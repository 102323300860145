import React from "react";
import { IoChevronDown } from "react-icons/io5";
import Logo from "../assets/primary.svg";
import { Link } from "react-router-dom";
import HeroBg from "../assets/herobg.png";
import Flag from "react-world-flags"; // Import the flag component

const Hero = () => {
  const scrollToNext = () => {
    const nextSection = document.getElementById("next-section");
    nextSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className="relative bg-cover bg-bottom text-white p-5 md:p-10"
        style={{ backgroundImage: `url(${HeroBg})` }}
      >
        <div className="absolute inset-0 bg-black opacity-95"></div>
        <div className="relative z-10">
          <div className="container mx-auto">
            <nav className="w-full gap-5 items-center justify-center flex">
              <img src={Logo} width={150} alt="" />
              <Flag code={"za"} width="24" className="rounded-sm pt-1.5" />

              <span className="text-xs tracking-widest px-2 rounded-full bg-main/10 mt-1 border-main/50 border text-main hidden">
                HIRING
              </span>
            </nav>
          </div>
          <div className="pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl ">
            <div className="max-w-2xl mx-auto sm:max-w-xl lg:min-w-full">
              <div className="text-center">
                <div className="max-w-3xl md:mx-auto sm:text-center lg:max-w-[90%] md:mb-12">
                  <h2 className="uppercase headline xl:hidden">
                    Grow a <span className="text-main">sustainable</span> real
                    estate business
                  </h2>
                  <h2 className="uppercase headline hidden xl:block">
                    Grow a sustainable real estate business-
                    <span className="text-main">
                      no burnout, no outdated methods.
                    </span>
                  </h2>
                  <div className="lg:max-w-[70%] mx-auto">
                    <p className="my-7 md:my-14 !mt-10 md:text-3xl text-xl">
                      There’s a smarter way to grow your business with less
                      hustle, creating more income, freedom, and impact.
                    </p>
                    <div className="flex">
                      <Link to="/strategy-session" className="w-full">
                        <button
                          className="funnelCTA"
                          style={{ backgroundColor: "#0086ff" }}
                        >
                          <div className="slant cta">
                            CLAIM MY FREE 20-MIN STRATEGY SESSION
                          </div>
                          <p
                            className="!font-bold text-xs md:text-sm mx-auto w-full slant mt-1"
                            style={{ color: `#00000070` }}
                          >
                            NO OBLIGATIONS. NO BS. NO HARD-SELL.
                          </p>
                        </button>
                      </Link>
                    </div>
                    <p className="text-sm text-center text-white mt-2">
                      Receive our "weird"{" "}
                      <span className="italic font-bold text-pink-500">
                        100% LEGAL
                      </span>{" "}
                      methods for FREE...
                    </p>

                    <div
                      onClick={scrollToNext}
                      className="cursor-pointer px-10 w-fit mx-auto mt-5"
                    >
                      <button className="p-3 rounded-full text-white animate-bounce-slow text-2xl">
                        <IoChevronDown />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
