import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X } from "lucide-react";
import { Link } from "react-scroll"; // Import react-scroll Link
import { useLocation, NavLink } from "react-router-dom"; // Use from react-router-domcurrent path
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import logo from "../assets/secondary.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation(); // Hook to get the current location

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 300); // Change background after 50px scroll
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Disable scrolling when the nav is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden"; // Add this to hide scrollbar
    } else {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto"; // Restore scrollbar
    }
    return () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto"; // Clean up and restore scrollbar
    };
  }, [isOpen]);

  const links = [
    { name: "Benefits", path: "features" },
    { name: "The Team", path: "team" },
    { name: "Faq", path: "questions" },
  ];

  return (
    <>
      <motion.div
        className={`fixed top-0 left-0 w-full z-50 flex ${
          scrolled ? "" : "bg-transparent"
        }`}
        initial={{ opacity: 0, y: -50 }} // Initial state: hidden and above
        animate={{
          opacity: scrolled ? 1 : 0, // Fade in when scrolled
          y: scrolled ? 0 : -50, // Slide in when scrolled
        }}
        transition={{
          duration: 0.2, // Smoother transition for both opacity and position
          ease: "easeOut", // Smooth easing
        }}
        style={{ pointerEvents: scrolled ? "auto" : "none" }} // Prevent interaction when not visible
      >
        <div className="mx-auto sm:max-w-xl md:max-w-full items-center justify-between flex w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-3 p-3 bg-white md:border md:rounded-2xl shadow-lg shadow-black/[3%] md:mt-2">
          <div className="flex items-center space-x-5">
            <div className="flex">
              <div className="relative">
                <span className="text-[9px] font-normal tracking-wide right-1 -top-1 flex items-center absolute">
                  Beta
                </span>
                <img src={logo} alt="Logo" className="w-32" />
              </div>
            </div>

            <Link
              to="features" // Use react-scroll Link instead of <Link> from react-router
              smooth={true} // Enables smooth scrolling
              duration={500} // Duration of scroll animation
              offset={-50} // Adjust offset if needed
              className="transition-all hover:text-main mt-1 z-30 hidden md:block cursor-pointer"
            >
              Benefits
            </Link>
            <Link
              to="pricing" // Use react-scroll Link instead of <Link> from react-router
              smooth={true} // Enables smooth scrolling
              duration={500} // Duration of scroll animation
              offset={-50} // Adjust offset if needed
              className="transition-all hover:text-main mt-1 z-30 hidden cursor-pointer"
            >
              Pricing
            </Link>
          </div>
          <div className="flex items-center gap-x-3 md:gap-x-5">
            <NavLink to="/apply">
              <button className="cta !rounded-xl !text-sm !font-medium !py-2 !h-fit">
                Claim My Demo
              </button>
            </NavLink>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className=" text-[#1f1f1f] rounded-full hover:scale-110 transition duration-200 !z-[50] relative"
            >
              <motion.div
                className="flex items-center justify-center"
                animate={{ rotate: isOpen ? 180 : 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                {isOpen ? <X size={25} /> : <Menu size={25} />}
              </motion.div>
            </button>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {isOpen && (
          <motion.nav
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: "easeOut",
              type: "slide",
              stiffness: 300,
              damping: 10,
            }}
            className="fixed inset-0 bg-gray-900/85 backdrop-blur-lg text-white flex flex-col justify-center items-center z-40"
          >
            <ul className="text-center flex flex-col items-center space-y-6">
              {links.map(({ name, path }, index) => (
                <motion.li
                  key={name}
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{
                    delay: 0.1 + index * 0.1,
                    duration: 0.5,
                    ease: "easeOut",
                  }}
                  className="relative flex items-center cursor-pointer"
                >
                  {location.pathname === path && (
                    <span className="absolute left-[-40px] h-5 w-5 shadow bg-main rounded-full"></span>
                  )}
                  <h3 className="drop-shadow">
                    <Link
                      to={path} // Use react-scroll Link instead of <Link> from react-router
                      smooth={true} // Enables smooth scrolling
                      duration={500} // Duration of scroll animation
                      offset={-50} // Adjust offset if needed
                      className="hover:text-main transition-all hover:tracking-wide drop-shadow-lg"
                      onClick={() => setIsOpen(false)}
                    >
                      {name}
                    </Link>
                  </h3>
                </motion.li>
              ))}
            </ul>

            {/* Social Media Icons */}
            <div className="absolute bottom-10 flex space-x-5">
              <a
                href="https://www.facebook.com/profile.php?id=61558385861304"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="text-white text-3xl md:text-4xl hover:text-main transition drop-shadow" />
              </a>
              <a
                href="https://www.instagram.com/estatekit.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="text-white text-3xl md:text-4xl hover:text-main transition drop-shadow" />
              </a>
              <a
                href="https://www.linkedin.com/company/105678294"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="text-white text-3xl md:text-4xl hover:text-main transition drop-shadow" />
              </a>
              <a
                href="https://wa.me/+264852878236"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp className="text-white text-3xl md:text-4xl hover:text-main transition drop-shadow" />
              </a>
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
