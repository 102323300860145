import Cal, { getCalApi } from "@calcom/embed-react";
  import { useEffect } from "react";
  export default function MyApp() {
	useEffect(()=>{
	  (async function () {
		const cal = await getCalApi({"namespace":"estatekit-demo"});
		cal("ui", {"theme":"light","cssVarsPerTheme":{"light":{"cal-brand":"#0086ff"},"dark":{"cal-brand":"#0086ff"}},"hideEventTypeDetails":false,"layout":"month_view"});
	  })();
	}, [])
	return <Cal namespace="estatekit-demo"
	  calLink="estatekit/estatekit-demo"
	  style={{width:"100%",height:"100%",overflow:"scroll"}}
	  config={{"layout":"month_view","theme":"light"}}
    
	  
	/>;
  };
  