import Glow from "../assets/top-highlight.png";
import React from "react";
import { Link } from "react-router-dom";

const EarlyAccess = () => {
  return (
    <div className="relative overflow-hidden bg-cover bg-center">
      <div className="pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:pb-20">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12 md:rounded-3xl bg-gradient-to-b to-gray-800 from-gray-900 relative p-5 py-10 md:p-10 md:pt-16 gap-y-7 flex flex-col">
              <img
                src={Glow}
                className="mx-auto transform rotate-180 absolute opacity-80 -bottom-32 left-0"
                alt=""
              />
              <div>
                <p className="font-extrabold !text-white !text-4xl !leading-none capitalize">
                  Early Access – Directly Shape EstateKit!
                </p>
              </div>
              <div className="">
                <p className="md:text-xl text-lg leading-tight mx-auto !text-white">
                We’re not a big corporation, just a passionate startup, building EstateKit with agents like YOU in mind. Together, we can create something truly great. Be part of this journey with us.
                </p>
              </div>
              <div className="flex items-center justify-center md:flex-row w-full z-20 md:pb-0 gap-3 max-w-xl mx-auto flex-col-reverse">
                {/* <Link
                  to="/apply"
                  className="bg-white/10 w-full py-4 md:px-16 rounded-xl border border-white/10 hover:bg-white/[13%] transition-all col-"
                >
                  <button className="!text-white">
                    <div className="cta">Try EstateKit Free</div>
                  </button>
                </Link> */}
                <Link
                  to="/apply"
                  className="bg-main w-full py-4 md:px-16 !max-w-sm rounded-xl border border-main hover:bg-main/[95%] transition-all"
                >
                  <button className="!text-white">
                    <div className="cta">Claim My Demo</div>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EarlyAccess;
