import React, { useState } from "react";
import screenshots from "../assets/screenshots.png";
import screenshots2 from "../assets/screenshots2.png";
import { motion } from "framer-motion";
import {
  HomeIcon as HomeIconSolid,
  ChartBarIcon as ChartBarIconSolid,
  CollectionIcon as CollectionIconSolid,
  ShareIcon as ShareIconSolid,
  ViewGridIcon as ViewGridIconSolid,
  ClipboardListIcon as ClipboardListIconSolid,
  HeartIcon as HeartIconSolid,
} from "@heroicons/react/solid";
import {
  HomeIcon as HomeIconOutline,
  ChartBarIcon as ChartBarIconOutline,
  CollectionIcon as CollectionIconOutline,
  ShareIcon as ShareIconOutline,
  ViewGridIcon as ViewGridIconOutline,
  ClipboardListIcon as ClipboardListIconOutline,
  HeartIcon as HeartIconOutline,
} from "@heroicons/react/outline";

const features = [
  {
    solidIcon: <HomeIconSolid className="h-6 w-6" />,
    outlineIcon: <HomeIconOutline className="h-6 w-6" />,
    text: "Personalized Landing Page",
    description:
      "Turn visitors into hot leads with a sleek, high-performing page designed to convert.",
  },
  {
    solidIcon: <ChartBarIconSolid className="h-6 w-6" />,
    outlineIcon: <ChartBarIconOutline className="h-6 w-6" />,
    text: "Landing Page Analytics",
    description:
      "See exactly where your leads come from and optimize your page for maximum impact.",
  },
  {
    solidIcon: <CollectionIconSolid className="h-6 w-6" />,
    outlineIcon: <CollectionIconOutline className="h-6 w-6" />,
    text: "Unlimited Listings",
    description:
      "Showcase all your properties without limits—because more listings mean more deals.",
  },
  {
    solidIcon: <ShareIconSolid className="h-6 w-6" />,
    outlineIcon: <ShareIconOutline className="h-6 w-6" />,
    text: "Social Media Integration",
    description:
      "Instantly share your listings on Facebook, Instagram, and more to attract buyers fast.",
  },
  {
    solidIcon: <ViewGridIconSolid className="h-6 w-6" />,
    outlineIcon: <ViewGridIconOutline className="h-6 w-6" />,
    text: "Dashboard Access",
    description:
      "All your leads, listings, and insights in one sleek, easy-to-use control center.",
  },
  {
    solidIcon: <ClipboardListIconSolid className="h-6 w-6" />,
    outlineIcon: <ClipboardListIconOutline className="h-6 w-6" />,
    text: "Customizable Lead Form",
    description:
      "Capture exactly the details you need with forms that fit your business.",
  },
  {
    solidIcon: <HeartIconSolid className="h-6 w-6" />,
    outlineIcon: <HeartIconOutline className="h-6 w-6" />,
    text: "...and so much more!",
    description:
      "We’re constantly adding new tools to keep you ahead in the market.",
  },
];

const Features = () => {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);
  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const day = currentDate.getDate();
  return (
    <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:pb-20 lg:pt-5" id="start">
      <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
        <div className="letter !mb-5 mx-5 md:mx-0">
          <div className="!text-base border-b-4 border-main w-fit pb-5">
            <p>
              <span className="font-semibold">From: </span>
              The estatekit team
            </p>
            <p>
              <span className="font-semibold">Date: </span>
              2 April, {currentDate.getFullYear()}
            </p>
          </div>
          <p>Dear real estate agent,</p>
          <p>Let’s keep it real.</p>
          <p>You’re hustling every day, trying to get leads in the door.</p>
          <p>
            Whether you’ve got a website or not, that part is always a pain,
            right?
          </p>
          <p>
            You’re posting on social media, running ads, maybe even cold
            calling.
          </p>
          <p>If you’ve got a website, that’s great. </p>
          <p>
            But... is it actually doing the job? Is it bringing in leads, or is
            it just sitting there looking nice, but empty?
          </p>
          <p></p>
          <p>Yeah, we’ve seen it. It’s frustrating, to say the least.</p>
          <p>
            And if you don’t have a website yet, well, I get it. But where do
            you actually send people to?
          </p>
          <p id="features">Where do you catch those leads?</p>
          <p>Your inbox? Your DMs...? Email? It’s a lot, I know.</p>
          <p>Check this out...</p>
          <div className="flex flex-col !mt-20 gap-5">
            <h3 className="md:hidden flex">Built by agents, for agents</h3>
            <h3 className="hidden md:flex">It's built by agents, for agents</h3>
            <p className="text-center md:pb-5">
              Plus, we’ve stripped away the nonsense. No complicated setups. No tech
              headaches. Just pure, lead-capturing firepower
            </p>
          </div>
        </div>
        <img
          src={screenshots}
          className="my-32 hidden md:flex mx-auto scale-110 md:scale-150"
          alt="Feature Screenshot"
        />
        
        <img
          src={screenshots2}
          className=" md:hidden  mx-auto scale-110"
          alt="Feature Screenshot"
        />
        
        <div className="flex overflow-x-auto py-2 w-full px-5 md:px-0 space-x-10 md:space-x-5 mt-10 scrollbar-hide sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="cursor-pointer flex flex-col items-center !min-w-20"
              onClick={() => setSelectedFeature(feature)}
            >
              <div
                className={`flex flex-col text-xl h-16 w-16 mx-auto justify-center rounded-full items-center border transition-all duration-[0.3] ${
                  selectedFeature.text === feature.text
                    ? "!bg-main !text-white !text-2xl transform -translate-y-2"
                    : "bg-white text-gray-800"
                }`}
              >
                {selectedFeature.text === feature.text
                  ? feature.solidIcon
                  : feature.outlineIcon}
              </div>
              <p
                className={`text-xs md:text-sm font-medium mt-2 text-center !leading-none transition-all duration-[0.3] ${
                  selectedFeature.text === feature.text
                    ? "!text-main font-semibold"
                    : " text-gray-800"
                }`}
              >
                {feature.text}
              </p>
            </motion.div>
          ))}
        </div>
        <div className="mt-6 p-6 bg-white rounded-3xl border text-left md:w-full md:mx-auto mx-5">
          <motion.div
            key={selectedFeature.text}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="flex flex-col md:flex-row md:items-center gap-5"
          >
            <div className="w-10">
              <h4 className="text-3xl font-bold">
                {selectedFeature.solidIcon}
              </h4>
            </div>
            <div>
              <h4 className="text-lg font-bold">{selectedFeature.text}</h4>
              <p className="text-gray-600">{selectedFeature.description}</p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Features;
