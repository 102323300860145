import React from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import ClientSlider from "./ClientSlider";
import { IoChevronDown } from "react-icons/io5";
import { Link } from "react-scroll"; // Import react-scroll Link

import jareer from "../assets/jareer.jpg";
import aldredt from "../assets/aldredt.jpg";
import kieran from "../assets/kieran.png";

const Team = () => {
  return (
    <div
      className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:pb-20 lg:pt-5"
      id="team"
    >
      <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
        <div className="letter !mb-5 mx-5 md:mx-0">
          <p>
            We get it, choosing the right tool isn’t easy. Right now, it’s just
            the three of us on the team.
          </p>
          <p></p>
          <p>
            Unlike bigger companies, you’re not just a number to us. We
            genuinely listen to you.
          </p>
          <p>
            Got ideas? Feature requests? We’re all ears. This is your
            opportunity to directly shape what we build, ensuring it works for
            YOU.
          </p>
          <p>Join us. Help us help you. We’ve got your back.</p>
          <div className="flex flex-col !mt-20 gap-5">
            <h3>Your growth team</h3>
            <p className="text-center md:pb-5">
              One mission, three people, obsessed with helping agents win. No
              fluff. No BS. Just real support and real solutions.
            </p>
          </div>
          <div className="grid lg:grid-cols-3 gap-10 py-5">
            <div className="flex flex-col gap-2">
              <img
                src={jareer}
                alt="Jareer"
                className="rounded-full mx-auto border-[5px] border-white drop-shadow-sm w-56 md:w-fit"
              />
              <div>
                <p className="text-center font-semibold">Jareer</p>
                <p className="text-center !text-xl">CTO</p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <img
                src={kieran}
                alt="kieran"
                className="rounded-full mx-auto border-[5px] border-white drop-shadow-sm w-56 md:w-fit"
              />
              <div>
                <p className="text-center font-semibold">Kieran</p>
                <p className="text-center !text-xl">Founder</p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <img
                src={aldredt}
                alt="aldredt"
                className="rounded-full mx-auto border-[5px] border-white drop-shadow-sm w-56 md:w-fit"
              />
              <div>
                <p className="text-center font-semibold">Aldredt</p>
                <p className="text-center !text-xl">COO</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
