import EstateKit from "./estatekit.svg";
import Loader from "./loader.svg";
import React from "react";
import "./index.css";

export default class LoadingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasLoaded: false };
  }

  componentDidMount() {
    const WaitBeforeExit = 2500;
    document.body.classList.add("loading");
    setTimeout(() => {
      this.setState({ hasLoaded: true });
      document.body.classList.remove("loading");
    }, WaitBeforeExit);
  }

  render() {
    return !this.state.hasLoaded ? (
      <main className="flex flex-wrap items-center justify-center h-screen w-full z-[1000] loading-screen no-select">
        <div className="w-full flex flex-wrap items-center justify-center">
          <div>
            <img
              src={EstateKit}
              alt="EstateKit Logo"
              className="no-drag"
              draggable="false"
            />
          </div>
          <div className="w-full spinning flex mt-5 flex-wrap justify-center">
            <img
              src={Loader}
              width={25}
              alt="Loading..."
              className="no-drag"
              draggable="false"
            />
          </div>
        </div>
      </main>
    ) : (
      <main></main>
    );
  }
}
