import React, { useEffect, useRef } from "react";
const Slider = () => {
  const leftRowRef = useRef(null);
  const rightRowRef = useRef(null);

  const slides = [
    "HIGH-CONVERTING LEADS",
    "OPTIMIZED WEBSITES",
    "AI-POWERED CONTENT",
    "AUTOMATED LEAD QUALIFICATION",
    "SMART MARKETING SOLUTIONS",
    "SELLER ENGAGEMENT",
    "BUYER ATTRACTION",
    "REAL-TIME LEAD CAPTURE",
    "INCREASED CONVERSIONS",
    "CUSTOMIZED CAMPAIGNS",
    "BUSINESS GROWTH TOOLS",
    "LEAD MANAGEMENT SIMPLIFIED",
    "EFFICIENT AUTOMATION",
    "SCALABLE SYSTEMS",
    "MOBILE-FIRST EXPERIENCE",
    "CLIENT RETENTION TOOLS",
    "LISTING PROMOTIONS",
    "TARGETED MARKETING CAMPAIGNS",
    "CONVERTING TRAFFIC TO CLIENTS",
    "STREAMLINED WORKFLOWS",
    "24/7 LEAD GENERATION",
    "SEAMLESS INTEGRATION",
    "NURTURING BUYERS & SELLERS",
    "SALES PIPELINE MANAGEMENT",
    "EXCEPTIONAL CUSTOMER SUPPORT",
    "DATA-DRIVEN INSIGHTS",
    "LEAD QUALIFICATION MADE EASY",
    "TURNKEY SOLUTIONS",
    "DEAL CLOSING SYSTEMS",
    "MARKETING AUTOMATION",
    "REMARKETING STRATEGIES",
    "PERFORMANCE-DRIVEN RESULTS",
    "FUTURE-PROOF TECHNOLOGY",
    "TIME-SAVING SOLUTIONS",
    "RELIABLE LEAD FLOW",
    "INCREASED SALES EFFICIENCY",
    "MORE CLOSED DEALS"
  ];
  

  // Function to initialize the infinite scroll
  const startScrolling = (rowRef, direction) => {
    const row = rowRef.current;
    const speed = direction === "left" ? 50 : -50; // Different speeds for left/right
    let scrollAmount = 0;

    const scroll = () => {
      scrollAmount += speed * 0.01; // Adjust scroll speed
      row.style.transform = `translateX(${scrollAmount}px)`;

      // Loop back if content is scrolled completely
      if (Math.abs(scrollAmount) >= row.scrollWidth / 2) {
        scrollAmount = 1;
      }

      requestAnimationFrame(scroll);
    };

    scroll();
  };

  useEffect(() => {
    startScrolling(rightRowRef, "right");
    startScrolling(leftRowRef, "left");
    startScrolling(rightRowRef, "right");
  }, []);

  return (
    <div className="sliding-container !overflow-hidden">
      <div className="sliding-wrapper">
        <div className="sliding-row right-slide" ref={rightRowRef}>
          {slides.concat(slides).map((slide, index) => (
            <div key={index} className="text-slide">
              {slide}
            </div>
          ))}
        </div>
      </div>

      <div className="sliding-wrapper">
        <div className="sliding-row right-slide" ref={leftRowRef}>
          {slides.concat(slides).map((slide, index) => (
            <div key={index} className="text-slide">
              {slide}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
