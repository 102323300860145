import React from "react";
import Logo from "../assets/secondary.svg";
import rocket from "../assets/rocket_1f680.png";
import { useParams } from "react-router-dom";
import Cal from "../components/Cal";

const OnboardThanks = ({ clientname }) => {
  return (
    <div className="funnel !overflow-visible">
      <nav className="w-full h-16 shadow-sm !bg-white flex items-center justify-center gap-5">
        <img src={Logo} width={140} alt="Company Logo" />
      </nav>
      <div className="text-center px-5 md:w-6/12 md:mx-auto mt-10 md:mt-20">
        <h2 className="!text-5xl md:!text-8xl gap-3 my-5">
          <span className="md:mr-5 mr-2">
            <img
              src={rocket}
              className="md:w-24 w-12 inline align-top"
              alt=""
            />
          </span>
          You're all set{clientname ? `, ${clientname}!` : "!"}
        </h2>
        <p className="text-2xl md:text-3xl font-medium !text-center">
          Let's keep it easy... Pick a time below to chat with Kieran, He will
          show you how it works. If it’s a fit, great. If not, all good.
        </p>
      </div>
      <div className="mt-8 flex justify-center relative">
        <div className="bg-[#f4f4f8] w-32 hidden md:flex h-10 absolute z-50 bottom-5"></div>
        <Cal />
      </div>
    </div>
  );
};

export default OnboardThanks;
