import React, { useState, useEffect } from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { NavLink, useParams, useLocation } from "react-router-dom";
import ClientSlider from "./ClientSlider";
import { IoChevronDown } from "react-icons/io5";
import Glow from "../assets/top-highlight.png";
import { Link } from "react-scroll"; // Import react-scroll Link

import Logo from "../assets/primary.svg";
import Swipe from "../assets/swipe.svg";
import wave from "../assets/waving-hand_1f44b.png";
import branding from "../assets/branding.svg";

const Slide = ({
  backgroundImage,
  gradient,
  title,
  subtitle,
  isActive,
  clientname,
}) => {
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState(""); // To store the country code
  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const day = currentDate.getDate();

  // Fetch the user's country name and country code using fetch API
  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((data) => {
        setCountry(data.country);
        setCountryCode(data.countryCode); // Set country code for flag
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
      });
  }, []);

  const location = useLocation(); // Use useLocation to get current URL path

  // Define the CTA NavLink dynamically based on the route
  const scrollToNext = () => {
    const nextSection = document.getElementById("next-section");
    nextSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className={`inset-0 ${gradient} w-full h-full flex flex-col justify-between gap-3 items-center text-white transition-transform duration-500 md:p-10 p-3 relative`}
    >
      <div
        className={`relative !z-20 text-center mb-auto space-y-4 md:space-y-5 mt-20 md:mt-16`}
      >
        <div className="rounded-full flex items-center justify-center mx-auto bg-white/5 !w-fit md:py-2 py-1 !leading-none px-3 backdrop-blur-[1px] border border-white/[5%]">
          <div className="flex items-center spots">
            <div className="relative w-3 h-3 flex items-center justify-center mr-2">
              <div className="absolute w-1.5 h-1.5 rounded-full bg-red-500 shadow z-10"></div>
              <div className="absolute w-3 h-3 rounded-full bg-red-600"></div>
              <div className="absolute w-3 h-3 rounded-full bg-red-600 animate-ping"></div>
            </div>
            <div className="flex items-center text-left !leading-none text-sm md:text-base">
              <p className="font-light text-white flex items-center gap-1">
                <span className="font-medium">UPCOMING: </span>
                {day} {month} | Now serving agents
              </p>
            </div>
          </div>
        </div>
        <h3 className="max-w-7xl !leading-[90%]">
          <span className="md:mr-5 mr-2">
            <img src={wave} className="md:w-24 w-12 inline align-top" alt="" />
          </span>
          Goodbye to Chasing Leads, Become a 24/7 Lead Magnet.
        </h3>

        <p className="md:text-3xl text-xl leading-tight md:leading-normal mx-auto max-w-6xl">
          Automate the process of capturing, qualifying and nurturing leads, so
          you can stop wasting time on unqualified prospects and start closing
          deals.
        </p>
      </div>
      <div className="!z-20">
        <ClientSlider />
      </div>
      <div className="flex flex-col md:flex-row w-full lg:max-w-3xl z-20 pb-4 md:pb-0 gap-3">
        <NavLink to={"/apply"} className="w-full max-w-sm mx-auto">
          <button
            className="funnelCTA2 shadow  border border-white/5"
            style={{ backgroundColor: "#0086ff" }}
          >
            <div className="cta">Claim My Demo!</div>
          </button>
        </NavLink>
        <NavLink to={"/apply"} className="w-full hidden">
          <button className="funnelCTA2 border border-white/5 shadow !bg-white/10 !hover:bg-white/[13%]">
            <div className="cta">Claim My Demo</div>
          </button>
        </NavLink>
      </div>

      <Link
        to="start" // Use react-scroll Link instead of <Link> from react-router
        smooth={true} // Enables smooth scrolling
        duration={500} // Duration of scroll animation
        offset={-50} // Adjust offset if needed
        className="cursor-pointer px-10 w-fit mx-auto md:mt-5 flex items-center z-20 "
      >
        <button className="rounded-full text-white animate-bounce-slow text-2xl">
          <IoChevronDown />
        </button>
      </Link>
    </div>
  );
};

const NewHero = () => {
  const { clientname } = useParams(); // Capture clientname from the URL
  const [current, setCurrent] = useState(0);

  const slides = [
    {
      gradient: "bg-gradient-to-b from-gray-900 to-black",
      subtitle: `97% of agents are throwing leads away. Your "profile" or "microsite" isn’t cutting it, and your lack of online presence makes it worse. You know it, and your clients know it too.`,
    },
  ];

  const nextSlide = () => {
    setCurrent((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrent((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-black">
      {/* Gradient Background applied here */}
      <div className="w-full z-30 top-5 flex items-center justify-center absolute gap-x-10">
        <div className="flex items-center gap-2 !z-50">
          <img src={Logo} alt="Logo" className="w-40 z-20 drop-shadow" />
        </div>
        <img
          src={Glow}
          alt="Logo"
          className="lg:w-[65%] scale-125 absolute z-10 lg:-top-52 -top-20 "
        />
      </div>
      <div className="relative w-screen h-fit flex flex-col items-center justify-between">
        <img
          src={Swipe}
          width={40}
          className="z-30 top-60 left-60 absolute drop-shadow-lg hidden"
          alt="Swipe"
        />
        {slides.map((slide, index) => (
          <Slide
            key={index}
            backgroundImage={slide.backgroundImage}
            gradient={slide.gradient}
            title={slide.title}
            subtitle={slide.subtitle}
            isActive={index === current}
            clientname={clientname} // Pass clientname to Slide
          />
        ))}
      </div>
    </div>
  );
};

export default NewHero;
