import React, { useEffect } from "react";
import Button from "./Button";
import Guide from "../assets/guide.png";
import { Link, useParams } from "react-router-dom";

import { IoCloseSharp } from "react-icons/io5";

const Wait = ({ isVisible, onClose }) => {
  useEffect(() => {
    // Add or remove class based on modal visibility
    if (isVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Cleanup function to remove the class when the modal is closed
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-[#f3f3f3] px-10 pb-4 pt-10 rounded-[40px] shadow-lg max-w-2xl w-full text-center relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="text-[30px] absolute right-5 top-5 text-gray-400 p-3 cursor-pointer"
          onClick={onClose}
        >
          <IoCloseSharp />
        </div>
        <h2 className="!text-8xl slant">32 SECONDS...</h2>
        <p className="text-2xl my-5">
          That’s all it takes to complete the form &{" "}
          <span className="font-bold">
            claim your secret guide{" "}
            <span className="relative">
              R470{" "}
              <div className="w-12 rotate-6 top-3 h-1 bg-black absolute left-0"></div>
            </span>{" "}
            FREE.
          </span>
        </p>
        <div className="relative">
          <p className="text-3xl text-white uppercase absolute font-black bottom-10 left-52 -rotate-12">
            *focus*
          </p>
          <img
            src={Guide}
            className="w-full md:w-full z-20 -mt-3 rotate-[4deg]"
            alt="Guide"
          />
        </div>
        <div onClick={onClose}>
          <Button cta="CLAIM R470 GUIDE FOR FREE!"  color="#0086FF" />
        </div>
      </div>
    </div>
  );
};

export default Wait;
