import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Swipe from "../assets/swipe.svg";
import "swiper/swiper-bundle.css";
import conrad from "../assets/conrad.png";
import marishka from "../assets/marishka.jpeg";
import hugo from "../assets/hugo.jpg";
import diane from "../assets/diane.png";
import miranda from "../assets/miranda.png";
import bravey from "../assets/bravey.png";
import sharief from "../assets/sharief.PNG";
import warwick from "../assets/warwick.jpg"

const ClientSlider = () => {
  const [showSwipeIcon, setShowSwipeIcon] = useState(false);

  useEffect(() => {
    // Show the swipe icon after a slight delay (ensures visibility)
    const timer = setTimeout(() => {
      setShowSwipeIcon(true);
    }, 15000); // Delay to ensure visibility

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  const clients = [
    { name: "Hugo Henning", role: "eXp agent", image: hugo },
    { name: "Marishka Visser", role: "makalani real estate", image: marishka },
    { name: "Miranda Daline", role: "ilbab real estate", image: miranda },
    { name: "Bravey Amadilah", role: "country real estate", image: bravey },
    { name: "Warwick Ockhuizen", role: "ockhuizen real estate", image: warwick },
    { name: "Sharief Ismail", role: "eXp agent", image: sharief },
    { name: "Conrad Maritz", role: "eXp agent", image: conrad },
    { name: "Diane Brown", role: "eXp agent", image: diane },
  ];

  return (
    <div className="w-full max-w-5xl mx-auto relative block overflow-hidden">
      {showSwipeIcon && (
        <img
          src={Swipe}
          className="w-7 absolute swipe-icon flex transition-opacity duration-500 !z-30 top-0 left-32 md:left-10 md:top-0"
          alt="Swipe to see more"
        />
      )}
      <Swiper
        spaceBetween={6}
        slidesPerView={8}
        grabCursor={true}
        loop={true}
        onSlideChange={() => setShowSwipeIcon(false)} // Hide swipe icon after swipe
        breakpoints={{
          640: {
            slidesPerView: 6, // Adjust slides per view on smaller screens
          },
          768: {
            slidesPerView: 6, // Adjust slides per view on tablets
          },
          1024: {
            slidesPerView: 6, // Set slides per view on large screens
          },
        }}
        style={{
          WebkitMaskImage:
            "linear-gradient(to right, transparent, white 20%, white 80%, transparent)",
          maskImage:
            "linear-gradient(to right, transparent, white 20%, white 80%, transparent)",
          overflow: "hidden", // Prevent overflow on swiper itself
        }}
      >
        {[...clients, ...clients].map((client, index) => (
          <SwiperSlide key={index}>
            <a href={client.link} target="_blank" rel="noopener noreferrer">
              <div className="bg-gradient-to-b from-white/5 to-white/15 !backdrop-blur-lg !z-50 border-white/[7%] border rounded md:rounded-md pr-1 flex items-center gap-1 hover:-translate-y-1 duration-300 hover:border-main/[40%] hover:from-main/5 hover:via-main/[15%] hover:to-main/35 transition-all mt-2 pfp">
                <div
                  className="rounded-sm md:rounded-l-md h-7 w-7 md:h-10 md:w-10 mr-1 bg-cover bg-center"
                  style={{ backgroundImage: `url(${client.image})` }}
                ></div>
                <div className="space-y-0.5">
                  <p className="text-[10px] font-medium md:text-xs !leading-none truncate max-w-20">{client.name}</p>
                  <div className="flex items-center italic text-white/70 text-[8px] md:text-[10px]">
                    <p className=" !leading-none truncate max-w-28">{client.role}</p>
                  </div>
                </div>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ClientSlider;
