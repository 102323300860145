import React, { useState } from "react";
import Button from "./Button";
import Speech from "../assets/speech.png";
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";

const faqData = [
  {
    question: "How will EstateKit actually help me get more leads?",
    answer:
      "EstateKit is designed to make lead generation easy. You get a personalized landing page where you can showcase your listings, track leads, and follow up with them—without the stress. It’s all about making things simple so you can focus on closing deals, not chasing leads.",
  },
  {
    question: "What’s so different about EstateKit compared to other options?",
    answer:
      "Well, unlike other platforms that just give you a website and leave you hanging, EstateKit helps you capture leads and stay organized. It’s made by agents, for agents—so we know what works and what doesn’t. Plus, we keep it simple with no tech headaches. What you see is what you get.",
  },
  {
    question: "How long will it take before I see results?",
    answer:
      "You’ll start seeing leads come in almost right away. With your custom landing page and easy-to-use tracking, you’ll get notifications when someone’s interested, which means you can act fast. Of course, things will keep getting better over time as you fine-tune your system.",
  },
  {
    question: "Do I have to be tech-savvy to use EstateKit?",
    answer:
      "Not at all! EstateKit is designed to be simple and easy to use, even if you’re not a tech guru. You don’t need to know coding or anything complicated. If you can click a few buttons, you’ve got this.",
  },
  {
    question: "Can I get support if I need help along the way?",
    answer:
      "Absolutely! We’re a small team, so when you reach out, you’re talking to real people who genuinely care. We’re here to make sure you have everything you need to succeed, whether that’s help setting up your page, troubleshooting, or just answering questions.",
  },
  {
    question: "How do I track and manage my leads?",
    answer:
      "With EstateKit, you get access to a dashboard where you can track all your leads in one place. When someone shows interest, you’ll get an alert right away, so you can jump in and follow up. It’s all about making your life easier by keeping everything organized.",
  },
];

const Questions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="relative overflow-hidden bg-cover bg-center" id="questions">
      <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20 px-5">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="flex items-center justify-center gap-5 w-28 mx-auto mb-5">
                <img src={Speech} alt="Speech bubble icon" />
              </div>
              <div className="letter !mb-5 mx-5 md:mx-0">
                <div className="flex flex-col !mt-20 gap-5">
                  <h3>
                    Need Some Clarity?
                  </h3>
                </div>
              </div>

              {/* FAQ Section */}
              <div className="faq-container space-y-3 mt-10">
                {faqData.map((faq, index) => (
                  <div
                    key={index}
                    className="border rounded-3xl px-5 md:px-10 py-5 bg-white cursor-pointer"
                    onClick={() => toggleQuestion(index)}
                  >
                    <div className="flex justify-between items-center ">
                      <p className="font-semibold text-xl text-left md:text-2xl opacity-90 !leading-none">
                        {faq.question}
                      </p>
                      <span className="!text-3xl">
                        {activeIndex === index ? (
                          <IoChevronDown />
                        ) : (
                          <IoChevronUp />
                        )}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <div className="mt-5 text-lg md:text-xl text-left opacity-70">
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
