import shush from "../assets/shushing-face_1f92b.png";
import sparkles from "../assets/sparkles_2728.png";

const Start = ({ clientname }) => {
  return (
    <>
      <div className="funnel !overflow-visible mt-10">
        <div className="items-center justify-center gap-2 flex md:mt-20">
          <img src={shush} className="w-16 md:w-28" alt="" />
          <img src={sparkles} className="w-16 md:w-28" alt="" />
        </div>
        <h2 className="md:mx-auto md:w-6/12 !text-6xl md:!text-8xl gap-3 my-5">
          {clientname ? `Hi ${clientname},` : ""} Apply for Your Free Demo!
        </h2>
        <p className="text-2xl text-center md:text-3xl font-medium md:w-3/6 md:mx-auto mb-10">
          This is your{" "}
          <span className="font-black">
            <span className="bg-gradient-to-r from-yellow-500 via-yellow-500 to-yellow-600 bg-clip-text text-transparent italic uppercase ">
              golden ticket
            </span>
          </span>{" "}
          to a system so powerful it will make your competition{" "}
          <span className="font-bold">jealous!</span>
          <br />
          <br />
          Answer a few quick questions to see if you qualify.
        </p>
      </div>
    </>
  );
};

export default Start;
