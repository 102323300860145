import React, { useState, useEffect } from "react";
import "./funnel.css";
import Hero from "./components/Hero";
import Letter from "./components/Letter";
import Slider from "./components/Slider";
import Guarantee from "./components/Guarantee";
import Magnet from "./components/Magnet";
import Requirements from "./components/Requirements";
import Questions from "./components/Questions";
import Wait from "./components/Wait";
import Services from "./components/Services";
import NewHero from "./components/NewHero";
import Navbar from "./components/Navbar";
import EarlyAccess from "./components/EarlyAccess";
import Pricing from "./components/Pricing";
import Features from "./components/Features";
import Team from "./components/Team";

const Funnel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);

  useEffect(() => {
    const handleMouseLeave = (event) => {
      // Only trigger the modal if it hasn't been shown yet
      if (!hasModalBeenShown && event.clientY <= 0) {
        setIsModalVisible(true);
        setHasModalBeenShown(true); // Ensure the modal is not triggered again
      }
    };

    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [hasModalBeenShown]);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <section className="funnel bg-[#F3F3F3]  overflow-x-hidden">
      <Navbar />
      <section className="">
        <NewHero />
        <Features />
        <Pricing />
        <EarlyAccess />
        <Slider />
        <Team/>
        <Questions />
        <p className="w-full py-10 items-center justify-center text-center px-5">
          EstateKit - © 2025 All Rights Reserved | All Wrongs Reversed
        </p>
      </section>
      {/*
      <Wait isVisible={isModalVisible} onClose={closeModal} />
      */}
    </section>
  );
};

export default Funnel;
