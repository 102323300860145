import React, { useState, useEffect } from "react";
import ticket from "../assets/ticket.png";
import { useParams } from "react-router-dom";
import socket from "../../socket";
import ticketActive from "../assets/ticket-active.png";
import Logo from "../assets/secondary.svg";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import Start from "./Start";
import Drag from "../assets/drag.svg";
import LoadingScreen from "../components/LoadingScreen";
import Platforms from "../onboarding form/Platforms";

const OnboardingForm = () => {
  const { clientname } = useParams();
  const [step, setStep] = useState(1);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [formData, setFormData] = useState({
    business: "",
    website: "",
    goal: "",
    role: "",
    platforms: setSelectedPlatforms,
    socials: "",
    phone: "",
    email: "",
    area: "",
    aim: "",
  });
  const [isSliderDragged, setIsSliderDragged] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const makeWebhookUrl =
    "https://hook.eu2.make.com/adjc2fl6ohnh6g5qpp4dp4kphmytpxkt";

  const sendToMakeWebhook = async (formData) => {
    try {
      await fetch(makeWebhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      console.log("Data sent to Make webhook successfully.");
    } catch (error) {
      console.error("Error sending data to Make webhook:", error);
    }
  };
  useEffect(() => {
    validateForm();
  }, [formData, step, selectedPlatforms, isSliderDragged]);

  const validateForm = () => {
    switch (step) {
      case 2:
        setIsFormValid(formData.business.trim() !== "");
        break;
      case 3:
        setIsFormValid(formData.website.trim() !== "");
        break;
      case 4:
        setIsFormValid(formData.role.trim() !== "");
        break;
      case 5:
        setIsFormValid(selectedPlatforms.length > 0);
        break;
      case 6:
        setIsFormValid(formData.area.trim() !== "");
        break;
      case 7:
        setIsFormValid(formData.aim !== "");
        break;
      case 8:
        setIsFormValid(
          formData.phone.length > 9 && formData.email.trim() !== ""
        );
        break;
      default:
        setIsFormValid(true);
    }
  };
  const nextStep = async () => {
    if (step < 8) setStep(step + 1);
    else handleSubmit();
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const submissionData = {
      ...formData,
      selectedPlatforms: selectedPlatforms.join(", ") || "N/A", // Convert array to string
    };

    //await sendToMakeWebhook(submissionData);

    socket.emit("submitted", submissionData);

    const discordWebhookURL =
      "https://discord.com/api/webhooks/1338862719134335017/ZXpc6PzZ7Ie7jzQ_aVvCwmkBRr7lBRaLPPJRcGnXxZ1BoJxX11uzohYiyNc_t9LM_rD-";

    const embed = {
      username: "Application Bot",
      embeds: [
        {
          title: "🚀 **New Application Submitted**",
          color: 0x0086ff, // Blue
          fields: [
            {
              name: "👥 **Client Ref.**",
              value: clientname || "N/A",
              inline: true,
            },
            {
              name: "🏢 **Name**",
              value: submissionData.business || "N/A",
              inline: true,
            },
            {
              name: "📩 **Email**",
              value: submissionData.email || "N/A",
            },
            {
              name: "📞 **Phone**",
              value: submissionData.phone || "N/A",
            },
            { name: "🌐 **Website**", value: submissionData.website || "None" },
            { name: "💼 **Role**", value: submissionData.role || "N/A" },
            {
              name: "💡 **Lead Generation Platforms**",
              value: submissionData.selectedPlatforms || "N/A",
            }, // <-- Added this
            {
              name: "🗺 **Areas of Interest**",
              value: submissionData.area || "N/A",
            },
            {
              name: "🏡 **Transactions (2 months)**",
              value: submissionData.aim || "N/A",
            },
          ],
          footer: {
            text: `Submitted by ${
              submissionData.business
            } • ${new Date().toLocaleString()}`,
          },
        },
      ],
    };

    await fetch(discordWebhookURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(embed),
    });

    window.location.href = "/demo";
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <Start clientname={clientname} />;
      case 2:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">Okay, we'll make this quick and simple (promise). Let's start with your name?</h2>
            <input
              type="text"
              name="business"
              placeholder={`John Smith`}
              value={formData.business}
              onChange={handleChange}
              className="form-input"
            />
          </>
        );
      case 3:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              {formData.business}, what's your website url?
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              Type "none" if you don't have one.
            </p>
            <input
              type="text"
              name="website"
              placeholder="estatekit.co"
              value={formData.website}
              onChange={handleChange}
              className="form-input"
            />
          </>
        );
      case 4:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              What’s your role in real estate?
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500 lg:w-6/12 mx-auto">
              (E.g., Agent, Team Leader, Broker—this helps us personalize our
              chat for you.)
            </p>
            <input
              type="text"
              name="role"
              placeholder="property practitioner"
              value={formData.role}
              onChange={handleChange}
              className="form-input"
            />
          </>
        );
      case 5:
        return <Platforms setSelectedPlatforms={setSelectedPlatforms} />;
      case 6:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">What country are you based in?</h2>
            <input
              type="text"
              name="area"
              placeholder="South Africa"
              value={formData.area}
              onChange={handleChange}
              className="form-input"
            />
          </>
        );
      case 7:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              How many transactions have you done in the past two months?
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              This helps us make sure our chat is focused on strategies that
              actually move the needle for you.
            </p>
            <div className="lg:w-3/12 mx-auto">
              <div className="w-[250px] h-16 flex items-center justify-center rounded-full bg-[#fff] mx-auto mt-10 border-2">
                <div className="flex justify-center text-3xl font-normal">
                  <span>
                    {formData.aim >= 25 ? "25+" : formData.aim.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="relative">
                <input
                  type="range"
                  name="aim"
                  min="0"
                  max="25"
                  step="1"
                  value={formData.aim}
                  onChange={handleChange}
                  className="w-full slider-input"
                />
                <img
                  src={Drag}
                  className="w-32 absolute -left-36 top-2"
                  alt=""
                />
              </div>
            </div>
          </>
        );
      case 8:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              Almost there! Just a few more details to fill in.
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              Once you’ve filled this in, you’ll be able to book a time that
              suits you.
            </p>
            <div className="mx-auto flex flex-col mt-10">
              <input
                type="text"
                name="phone"
                placeholder="Phone number"
                value={formData.phone}
                onChange={handleChange}
                className="form-input !mt-2"
              />
              <input
                type="text"
                name="email"
                placeholder="Email address"
                value={formData.email}
                onChange={handleChange}
                className="form-input !mt-2"
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const progressPercentage = ((step - 1) / 7) * 100;

  return (
    <section className="mb-20">
      {showLoading && <LoadingScreen />}

      <nav className="w-full h-16 shadow-sm !bg-white flex items-center justify-center gap-5">
        <img src={Logo} width={140} alt="Company Logo" />
      </nav>

      <div className="md:w-4/12 mt-10 mx-5 md:mx-auto relative flex items-center">
        <div className="flex items-center justify-between w-full relative">
          {Array.from({ length: 8 }).map((_, index) => (
            <div
              key={index}
              className={`rounded-full h-4 md:h-5 w-4 md:w-5 ${
                step >= index + 1 ? "bg-main" : "bg-gray-200"
              }`}
            >
              {index === 4 && step === 8 && (
                <img
                  src={ticketActive}
                  alt="Active ticket"
                  className="absolute w-10 md:w-12 -right-3 -top-2 md:-right-6 md:-top-3 rotate"
                />
              )}
              {index === 4 && step < 8 && (
                <img
                  src={ticket}
                  alt="ticket"
                  className="absolute w-10 md:w-12 -right-3 -top-2 md:-right-6 md:-top-3 rotate"
                />
              )}
            </div>
          ))}
        </div>
        <div
          className="h-1 bg-main rounded-full absolute -z-10"
          style={{ width: `${progressPercentage}%` }}
        ></div>
        <div className="w-full h-1 bg-gray-200 rounded-full absolute -z-20"></div>
      </div>

      <div className="mt-10 text-center form-question mx-5">
        {renderStepContent()}
      </div>

      <div className="flex md:w-3/12 mx-5 md:mx-auto flex-col gap-2 items-center justify-center mt-5">
        <div className="flex w-full gap-2">
          <div className="flex flex-col w-full gap-4">
            <button
              className={`w-full text-2xl font-semibold rounded-xl p-5 ${
                isFormValid ? "bg-main text-white" : "bg-gray-300 text-gray-500"
              }`}
              onClick={nextStep}
              disabled={!isFormValid}
            >
              {step === 8 ? (
                "Submit"
              ) : (
                <span className="flex items-center gap-2 justify-center">
                  Continue
                  <FaChevronRight className="text-2xl" />
                </span>
              )}
            </button>
          </div>
          <button
            className={`text-2xl font-semibold text-gray-400 p-2 absolute top-3 lg:left-32 ${
              step === 1 ? "hidden" : ""
            }`}
            onClick={prevStep}
          >
            <FaChevronLeft className="text-2xl" />
          </button>
        </div>
        <p className="font-semibold uppercase text-xs text-center">
          💡 More details = a better qualification.
        </p>
      </div>
    </section>
  );
};

export default OnboardingForm;
