import React, { useState, useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";

import landing from "../assets/page.png";
import tracking from "../assets/track.png";
import converting from "../assets/convert.png";

// Reusable Pricing Card Component
const PricingCard = ({
  planName,
  planDescription,
  price,
  features,
  buttonText,
  isFreePlan, // Added flag to differentiate free plan
  formattedPrice,
}) => {
  return (
    <div className="bg-white flex flex-col justify-between overflow-hidden rounded-3xl border p-5 md:p-10">
      <div>
        <p className="text-gray-500 text-left">{planDescription}</p>
        <motion.p
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -5 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="text-4xl font-bold mt-5 text-left"
        >
          {formattedPrice}
        </motion.p>
        {isFreePlan && (
          <p className="text-gray-500 text-xs text-left bg-black/5 w-fit px-2 py-1 rounded-full mt-1">
            💙 This is 100% free. No credit card needed.
          </p>
        )}
        {!isFreePlan && (
          <p className="text-gray-500 text-xs text-left bg-black/5 w-fit px-2 py-1 rounded-full mt-1">
            No hidden fees. Cancel anytime.
          </p>
        )}
        <ul className="mt-5 space-y-3 text-gray-700">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-2">
              {/* Show the checkmark for free plan features */}
              {feature.isPaid ? (
                <IoCloseSharp className="text-red-500" size={20} />
              ) : (
                <IoCheckmarkSharp className="text-main" size={20} />
              )}
              {feature.name}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <button className="mt-5 w-full py-4 text-lg font-medium bg-gray-900 text-white rounded-xl transition-all hover:bg-main">
          {buttonText}
        </button>
      </div>
    </div>
  );
};

const Pricing = () => {
  const [isQuarterly, setIsQuarterly] = useState(false);
  const [formattedMonthlyPrice, setFormattedMonthlyPrice] = useState("");
  const [formattedQuarterlyPrice, setFormattedQuarterlyPrice] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("R");
  const [isSouthAfrica, setIsSouthAfrica] = useState(false);

  // Function to detect South Africa based on IP or GeoLocation using ip-api (No API Key Required)
  useEffect(() => {
    const checkLocation = async () => {
      try {
        const response = await fetch("http://ip-api.com/json");
        const data = await response.json();
        if (data.countryCode === "ZA") {
          setIsSouthAfrica(true);
          setCurrencySymbol("R");
        } else {
          setIsSouthAfrica(false);
          setCurrencySymbol("$"); // Default to USD
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    checkLocation();
  }, []);

  const freePlanFeatures = [
    { name: "High Conversion Landing Page", isPaid: false },
    { name: "Landing Page Analytics", isPaid: false },
    { name: "Unlimited Listings", isPaid: false },
    { name: "Social Media Integration", isPaid: false },
    { name: "Dashboard Access", isPaid: false },
  ];

  const proPlanFeatures = [
    { name: "All Features in Free Plan", isPaid: false },
    { name: "Lead Activity Notifications", isPaid: false },
    { name: "Unlimited Listing Images", isPaid: false },
    { name: "Early Access Features", isPaid: false },
    { name: "SEO Optimized", isPaid: false },
  ];

  // Update pricing based on the currency
  useEffect(() => {
    if (isSouthAfrica) {
      setFormattedMonthlyPrice("R199");
      setFormattedQuarterlyPrice("R449");
    } else {
      setFormattedMonthlyPrice("$20");
      setFormattedQuarterlyPrice("$49");
    }
  }, [isSouthAfrica]);

  // Toggle handler for switching between monthly/quarterly plans
  const togglePlan = useCallback(() => setIsQuarterly((prev) => !prev), []);

  return (
    <div className="relative overflow-hidden bg-cover bg-center " id="pricing">
      <div className="px-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="mb-16 flex flex-col">
                <div className="letter !mb-10">
                  <p>What if you had something that actually worked for you?</p>
                  <p>
                    Something that helps you track leads, follow up with them,
                    and just keep everything organized without driving you
                    crazy?
                  </p>
                  <p>That’s what we're all about.</p>
                  <p>
                    We take the stress out of lead generation, so you can focus
                    on doing what you’re really good at,
                  </p>
                  <p>closing deals.</p>
                  <p>And the best part? </p>
                  <p>
                    You don’t have to be some tech expert to use it.
                  </p>
                  <p>It's easy as 1 2 3</p>
                  <div className="grid lg:grid-cols-3 gap-10 py-5">
                    <div className=" gap-3 md:gap-1.5 flex flex-col">
                      <img className="h-32 w-fit" src={landing} alt="" />
                      <div className="flex gap-2 items-center">
                        <div className="bg-main text-white w-10 h-10 flex items-center justify-center text-xl font-extrabold rounded-full">
                          1
                        </div>
                        <p className="!text-xl font-semibold">
                          Get Your Landing Page
                        </p>
                      </div>
                      <p className="!text-base !text-gray-500">
                        Set up a page that showcases your listings and brings
                        in leads.
                      </p>
                    </div>
                    <div className=" gap-3 md:gap-1.5 flex flex-col">
                      <img className="h-32 w-fit" src={tracking} alt="" />
                      <div className="flex gap-2 items-center">
                        <div className="bg-main text-white w-10 h-10 flex items-center justify-center text-xl font-extrabold rounded-full">
                          2
                        </div>
                        <p className="!text-xl font-semibold">
                          Track Leads Instantly
                        </p>
                      </div>
                      <p className="!text-base !text-gray-500">
                        Get alerts when someone shows interest.
                      </p>
                    </div>
                    <div className=" gap-3 md:gap-1.5 flex flex-col">
                      <img className="h-32 w-fit" src={converting} alt="" />
                      <div className="flex gap-2 items-center">
                        <div className="bg-main text-white w-10 h-10 flex items-center justify-center text-xl font-extrabold rounded-full">
                          3
                        </div>
                        <p className="!text-xl font-semibold">
                          Close More Deals
                        </p>
                      </div>
                      <p className="!text-base !text-gray-500">
                        Use the data to turn leads into clients.
                      </p>
                    </div>
                  </div>
                  <p>So stop stressing about leads.</p>
                  <p>
                    Let’s get things organized and get the business rolling in
                    the right direction.
                  </p>
                  <p>You’ve got enough on your plate.</p>
                  <p>It’s time to get started and watch your business grow.</p>

                  <div className="flex flex-col !mt-20 gap-5 hidden">
                    <h3>Simple Pricing</h3>
                    <p className="md:text-3xl text-xl leading-tight md:leading-normal mx-auto max-w-6xl">
                      Flat Rate. Pause or Cancel Anytime.
                    </p>

                    {/* Pricing Toggle Switch */}
                    <div
                      className="mx-auto rounded-xl bg-white border p-1 w-60 flex cursor-pointer relative"
                      onClick={togglePlan}
                    >
                      {/* Toggle Background Animation */}
                      <motion.div
                        className="absolute top-1 left-1 w-1/2 h-[80%] bg-main rounded-lg"
                        initial={false}
                        animate={{ x: isQuarterly ? "93%" : "0%" }}
                        transition={{ duration: 0.1, ease: "easeInOut" }}
                      />
                      <div
                        className={`w-full text-center py-1 z-10 !text-base transition-all ${
                          !isQuarterly ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Monthly
                      </div>
                      <div
                        className={`w-full text-center py-1 z-10 !text-base transition-all ${
                          isQuarterly ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Quarterly
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lg:flex-row gap-10 items-stretch flex flex-col-reverse hidden">
                  {/* Free Plan */}
                  <PricingCard
                    planName="Free"
                    planDescription="A complete system with essential features to start growing your leads."
                    price="Free"
                    features={freePlanFeatures}
                    buttonText="Try EstateKit Free"
                    isFreePlan={true}
                    formattedPrice="Free"
                  />

                  {/* Pro Plan */}
                  <PricingCard
                    planName="Pro"
                    planDescription="Support EstateKit’s growth and get early access to new features and perks."
                    price={
                      isQuarterly
                        ? formattedQuarterlyPrice
                        : formattedMonthlyPrice
                    }
                    features={proPlanFeatures}
                    buttonText="Claim My Demo"
                    isFreePlan={false} // Not free plan, so no "No hidden fees" message
                    formattedPrice={
                      isQuarterly
                        ? formattedQuarterlyPrice
                        : formattedMonthlyPrice
                    }
                  />
                </div>
                <div className="letter !mb-0 hidden">
                  <p>
                    We get it, choosing the right tool isn’t easy. Right now,
                    it’s just the three of us on the team.
                  </p>
                  <p></p>
                  <p>
                    Unlike bigger companies, you’re not just a number to us. We
                    genuinely listen to you.
                  </p>
                  <p>
                    Got ideas? Feature requests? We’re all ears. This is your
                    opportunity to directly shape what we build, ensuring it
                    works for YOU.
                  </p>
                  <p>Join us. Help us help you. We’ve got your back.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
