import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import Gift from "../assets/gift.png";
import socket from "../../socket";
import GiftActive from "../assets/gift-active.png";
import Drag from "../assets/drag.svg";
import Logo from "../assets/primary.svg";
import Sparkle from "../assets/sparkles_2728.png";
import Flag from "react-world-flags"; // Import the flag component
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import Platforms from "./Platforms";
import { IoCheckmarkCircle } from "react-icons/io5";
import Intro from "./Intro";
import Wait from "../components/Wait";
import LoadingScreen from "../components/LoadingScreen";

import config from "../../config";

const StrategyForm = () => {
  const [countryCode, setCountryCode] = useState("US"); // Default to US
  const [step, setStep] = useState(1);
  const [currency, setCurrency] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    budget: 0,
    current: 0,
    aim: 0,
    painPoint: "",
    callPromise: "",
    startTime: "",
    goal: "",
    phoneNumber: "",
    emailAddress: "",
    companyName: "",
  });
  const [confetti, setConfetti] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSliderDragged, setIsSliderDragged] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const day = currentDate.getDate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);

  const makeWebhookUrl =
    "https://hook.eu2.make.com/adjc2fl6ohnh6g5qpp4dp4kphmytpxkt";

  const sendToMakeWebhook = async (formData) => {
    try {
      await fetch(makeWebhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      console.log("Data sent to Make webhook successfully.");
    } catch (error) {
      console.error("Error sending data to Make webhook:", error);
    }
  };

  useEffect(() => {
    const handleMouseLeave = (event) => {
      // Only trigger the modal if it hasn't been shown yet
      if (!hasModalBeenShown && event.clientY <= 0) {
        setIsModalVisible(true);
        setHasModalBeenShown(true); // Ensure the modal is not triggered again
      }
    };

    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [hasModalBeenShown]);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        // jub0t: Don't use this one, it's paid and bad.
        // const response = await fetch("https://ipapi.co/json/", { headers: { "Content-type": "application/json" } });
        // setCountryCode(data.country_code);

        const response = await fetch(`${config.MailServer}/ip-data`, {
          headers: {
            "Content-type": "application/json",
          },
          method: "GET",
        });

        const jsoned = await response.json();
        const data = jsoned.data;

        setUserIP(data.ip);
        setCountryCode(data.country_code);

        if (data.country_code != "US") {
          retreiveCurrencyInfo(data.country_code);
        } else {
          setCurrency({
            code: "$",
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUserCountryCode();
  }, []);

  useEffect(() => {
    validateForm();
  }, [formData, step, isSliderDragged]);

  useEffect(() => {
    if (confetti) {
      const timer = setTimeout(() => {
        setConfetti(false);
      }, 15000); // 15 seconds

      return () => clearTimeout(timer);
    }
  }, [confetti]);

  useEffect(() => {
    validateForm();
  }, [formData, step, selectedPlatforms]);

  const validateForm = () => {
    switch (step) {
      case 1:
        setIsFormValid(true);
        break;
      case 2:
        setIsFormValid(formData.firstName.trim() !== "");
        break;
      case 4:
        setIsFormValid(formData.budget >= 500);
        break;
      case 3:
        setIsFormValid(selectedPlatforms.length > 0);
        break;
      case 5:
        setIsFormValid(formData.painPoint.trim() !== "");
        break;
      case 6:
        setIsFormValid(formData.current >= 1);
        break;
      case 7:
        setIsFormValid(formData.aim >= 500);
        break;
      case 8:
        setIsFormValid(formData.startTime >= 1);
        break;
      case 9:
        setIsFormValid(formData.firstName >= 1);
        break;
      case 10:
        setIsFormValid(
          formData.firstName.trim() !== "" &&
            formData.phoneNumber.length > 9 &&
            formData.companyName.trim() !== "" &&
            formData.emailAddress.trim() !== ""
        );
        break;

      default:
        setIsFormValid(true);
    }
  };

  const getDeviceInfo = () => {
    let deviceInfo = navigator.userAgent;

    if (/Android/i.test(navigator.userAgent)) {
      deviceInfo = "Mobile - Android";
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      deviceInfo = "Mobile - iOS";
    } else if (/Linux/i.test(navigator.userAgent)) {
      deviceInfo = "Linux";
    } else if (/Windows/i.test(navigator.userAgent)) {
      deviceInfo = "Windows";
    } else if (/Mac/i.test(navigator.userAgent)) {
      deviceInfo = "Mac";
    }

    return deviceInfo;
  };

  const useOnPageLeave = (handler) => {
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        handler();
        event.preventDefault();
        return ""; // Some browsers require a return value for the unload event.
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [handler]);
  };

  const [userIP, setUserIP] = useState(null);
  // useEffect(() => {
  //   const response = fetch("http://ip-api.com/json", {
  //     method: "GET",
  //     headers: {
  //       "Content-type": "application/json"
  //     },
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       setUserIP(data.query)
  //     });
  // })

  // useOnPageLeave(async () => {
  //   await socket.emit("left", {
  //     ipAddress: userIP,
  //     deviceInfo: getDeviceInfo()
  //   })
  // });

  const nextStep = async () => {
    if (step < 10) {
      setStep(step + 1);
    } else {
      handleSubmit();
    }

    if (step == 9) {
      setConfetti(true);
    }

    if (step == 1) {
      socket.emit("opted", {
        ipAddress: userIP,
        deviceInfo: getDeviceInfo(),
      });
    }
  };

  const retreiveCurrencyInfo = (currency) => {
    fetch(`${config.MailServer}/currency/${currency}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCurrency(data.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setShowLoading(true);

    const submissionData = {
      ...formData,
      ipAddress: userIP,
      deviceInfo: getDeviceInfo(),
    };

    // Send data to your Make.com webhook
    await sendToMakeWebhook(submissionData);

    // Emit data through socket as before
    socket.emit("submitted", submissionData);

    // Redirect to the thank-you page
    window.location.href = "/thank-you";
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <Intro />;
      case 2:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              Alright, we'll keep this short and sweet (promise). What is your
              name?
            </h2>
            <input
              type="text"
              name="firstName"
              placeholder="Enter your first name"
              value={formData.firstName}
              onChange={handleChange}
              className="form-input capitalize"
            />
          </>
        );
      case 4:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              Great! What is your monthly marketing budget?
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              This helps us see if we'd be a good fit and identify what the best
              growth strategies are within your budget.
            </p>
            <div className="lg:w-3/12 mx-auto">
              <div className="w-[250px] h-16 flex items-center justify-center rounded-full bg-[#fff] mx-auto mt-10 border-2">
                <div className="flex justify-center text-3xl font-normal">
                  <span>
                    {currency?.code}
                    {formData.budget >= 100000
                      ? "100,000+"
                      : formData.budget.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="relative">
                <input
                  type="range"
                  name="budget"
                  min="500"
                  max="100000"
                  step="500"
                  value={formData.budget}
                  onChange={handleChange}
                  className="w-full slider-input"
                />

                <img
                  src={Drag}
                  className="w-32 absolute -left-36 top-2"
                  alt=""
                />
              </div>
            </div>
          </>
        );
      case 3:
        return <Platforms setSelectedPlatforms={setSelectedPlatforms} />;
      case 5:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              Be honest,{" "}
              <span className="capitalize">{formData.firstName}</span>. What is
              the biggest obstacle keeping you from growing your business?
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500 lg:w-6/12 mx-auto">
              (Don’t skip the hairy details. We need to know about every score
              and every sore…. so give as much detail as you can about WHAT it
              is you feel is holding you back)
            </p>
            <textarea
              name="painPoint"
              placeholder="Briefly describe your pain points..."
              value={formData.painPoint}
              onChange={handleChange}
              className="form-input h-52"
            ></textarea>
          </>
        );
      case 6:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              How many transactions have you done in the past two months?
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              We're asking so we only prescribe strategies that are going to
              have a meaningful impact on your business.
            </p>
            <div className="lg:w-3/12 mx-auto">
              <div className="w-[250px] h-16 flex items-center justify-center rounded-full bg-[#fff] mx-auto mt-10 border-2">
                <div className="flex justify-center text-3xl font-normal">
                  <span>
                    {formData.current >= 50
                      ? "50+"
                      : formData.current.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="relative">
                <input
                  type="range"
                  name="current"
                  min="1"
                  max="50"
                  step="1"
                  value={formData.current}
                  onChange={handleChange}
                  className="w-full slider-input"
                />

                <img
                  src={Drag}
                  className="w-32 absolute -left-36 top-2"
                  alt=""
                />
              </div>
            </div>
          </>
        );
      case 7:
        return (
          <>
            <h2 className="mx-auto">What is your TARGET monthly revenue?</h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              We're asking so we're able to build you a growth plan to help you
              reach your target.
            </p>
            <div className="lg:w-3/12 mx-auto">
              <div className="w-[250px] h-16 flex items-center justify-center rounded-full bg-[#fff] mx-auto mt-10 border-2">
                <div className="flex justify-center text-3xl font-normal">
                  <span>
                    {currency?.code}
                    {formData.aim >= 100000
                      ? "100,000+"
                      : formData.aim.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="relative">
                <input
                  type="range"
                  name="aim"
                  min="500"
                  max="100000"
                  step="500"
                  value={formData.aim}
                  onChange={handleChange}
                  className="w-full slider-input"
                />
                {/* Conditionally render the drag icon based on whether the slider has been dragged */}

                <img
                  src={Drag}
                  className="w-32 absolute -left-36 top-2"
                  alt=""
                />
              </div>
            </div>
          </>
        );
      case 8:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              Almost there! How soon are you looking to get started?
            </h2>
            <div className="flex justify-center flex-wrap gap-2 lg:w-5/12 mx-auto mt-10">
              {[
                "Immediately",
                "Within 2 weeks",
                "2-4 weeks",
                "4-6 weeks",
                "6 weeks+",
                "I'm just a dreamer",
              ].map((label, index) => (
                <label
                  key={index}
                  className={`flex items-center px-3 w-[350px] gap-3 h-[50px] rounded-full cursor-pointer text-lg relative ${
                    formData.startTime === label
                      ? "bg-white border-main border-2 text-main"
                      : "bg-white border-2"
                  }`}
                >
                  <IoCheckmarkCircle
                    className={`text-3xl left-2 rounded-full bg-[#F4F4F8] ${
                      formData.startTime === label
                        ? "text-main"
                        : "text-gray-300"
                    }`}
                  />
                  <input
                    type="radio"
                    name="startTime"
                    value={label}
                    checked={formData.startTime === label}
                    onChange={handleChange}
                    onClick={() => {
                      nextStep();
                    }}
                    className="hidden"
                  />
                  {label}
                </label>
              ))}
            </div>
          </>
        );
      case 9:
        return (
          <>
            <h2 className="lg:w-5/12 mx-auto">
              {formData.firstName}, If you qualify for a call, do you promise
              that you will show up at your selected time?
            </h2>
            <div className="flex justify-center gap-2 lg:w-2/12 mx-auto mt-10">
              {["No 👎", "Yes👍"].map((label, index) => (
                <label
                  key={index}
                  className={`flex flex-col justify-center items-center px-3 w-[350px] gap-3 h-32 rounded-xl cursor-pointer text-lg relative ${
                    formData.startTime === label
                      ? "bg-white border-main border-2 text-main"
                      : "bg-white border-2"
                  }`}
                >
                  <input
                    type="radio"
                    name="callPromise"
                    value={label}
                    checked={formData.callPromise === label}
                    onChange={handleChange}
                    onClick={nextStep}
                    className="hidden"
                  />
                  {label}
                </label>
              ))}
            </div>
          </>
        );
      case 10:
        return (
          <>
            <h2 className="lg:w-6/12 mx-auto">
              BOOYAH! 👊 We're confident we can help you achieve CRAZY growth
              with some of our proven marketing strategies. Quickly enter your
              details below!
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              Please fill out your details, you'll be taken to a calendar on the
              next page to pick a time and date that works for you.
            </p>
            <div className="mx-auto flex flex-col">
              <input
                type="text"
                name="firstName"
                placeholder="Enter your full name"
                value={formData.firstName}
                onChange={handleChange}
                className="form-input"
              />
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone number"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="form-input !mt-2"
              />
              <input
                type="text"
                name="emailAddress"
                placeholder="Email address"
                value={formData.emailAddress}
                onChange={handleChange}
                className="form-input !mt-2"
              />
              <input
                type="text"
                name="companyName"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleChange}
                className="form-input !mt-2"
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const progressPercentage = (() => {
    if (step <= 2) {
      return 0;
    } else if (step <= 4) {
      return 25;
    } else if (step <= 6) {
      return 50;
    } else if (step <= 8) {
      return 75;
    } else {
      return 100;
    }
  })();
  

  const [showLoading, setShowLoading] = useState(false);

  const handleButtonClick = () => {
    nextStep();
  };

  return (
    <section className="mb-20">
      {showLoading ? <LoadingScreen /> : null}

      <nav className="w-full h-16 shadow-sm !bg-white flex items-center justify-center gap-5">
        <img src={Logo} width={140} alt="Company Logo" />
        <Flag code={"za"} width="24" className="rounded-sm pt-1.5" />
      </nav>

      <div className="md:w-4/12 mt-10 mx-5 md:mx-auto relative flex items-center">
        <div className="flex items-center justify-between w-full relative">
        {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className={`rounded-full h-4 md:h-5 w-4 md:w-5 ${
                step >= index * 2 + 1 ? "bg-main" : "bg-gray-200"
              }`}
            >
              {index === 4 && step === 10 && (
                <img
                  src={GiftActive}
                  alt="Active Gift"
                  className="absolute w-7 md:w-12 -right-1.5 -top-1.5 md:-right-6 md:-top-3 rotate"
                />
              )}
              {index === 4 && step < 10 && (
                <img
                  src={Gift}
                  alt="Gift"
                  className="absolute w-7 md:w-12 -right-1.5 -top-1.5 md:-right-6 md:-top-3 rotate"
                />
              )}
            </div>
          ))}
        </div>
        <div
          className="h-1 bg-main rounded-full absolute -z-10"
          style={{ width: `${progressPercentage}%` }}
        ></div>
        <div className="w-full h-1 bg-gray-200 rounded-full absolute -z-20"></div>
      </div>

      <div className="mt-10 text-center form-question mx-5">
        {renderStepContent()}
      </div>

      <div className="flex md:w-3/12 mx-5 md:mx-auto flex-col gap-5 items-center justify-center mt-5">
        <div className="flex w-full gap-2">
          <div className="flex flex-col w-full gap-4">
            <button
              className={`w-full text-2xl font-semibold rounded-xl p-5 ${
                isFormValid ? "bg-main text-white" : "bg-gray-300 text-gray-500"
              }`}
              onClick={handleButtonClick}
              disabled={!isFormValid}
            >
              {step === 10 ? (
                "Submit"
              ) : (
                <span className="flex items-center gap-2 justify-center">
                  Continue
                  <FaChevronRight className="text-2xl" />
                </span>
              )}
            </button>
            <div className="flex flex-col gap-2 items-center justify-center">
              <div className="flex items-center gap-4">
                <div className="relative w-3 h-3 flex items-center justify-center">
                  <div className="absolute w-3 h-3 rounded-full bg-red-600"></div>
                  <div className="absolute w-3 h-3 rounded-full bg-red-600 animate-ping"></div>
                </div>
                <div className="flex items-center">
                  <p className="font-medium uppercase text-xs md:text-sm">
                    Few spots left as of{" "}
                    <span className="font-bold">
                      {month.toUpperCase()} {day}
                    </span>
                  </p>
                </div>
                <Flag code={countryCode} width="30" className="rounded-sm" />
              </div>
            </div>
          </div>
          <button
            className={`text-2xl font-semibold text-gray-400 p-2 absolute top-3 lg:left-32 ${
              step === 1 ? "hidden" : ""
            }`}
            onClick={prevStep}
          >
            <FaChevronLeft className="text-2xl" />
          </button>
        </div>
      </div>
      <div className="funnel">
        <Wait isVisible={isModalVisible} onClose={closeModal} />
      </div>
    </section>
  );
};

export default StrategyForm;
