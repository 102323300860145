import React from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";

// funnel
import Navbar from "./funnel/components/Navbar";
import Funnel from "./funnel/Funnel";
import StrategyForm from "./funnel/form/StrategyForm";
import LoadingScreen from "./funnel/components/LoadingScreen";
import Thanks from "./funnel/form/Thanks";
import OnboardThanks from "./funnel/onboarding form/OnboardThanks";
import OnboardingForm from "./funnel/onboarding form/OnboardingForm";
import Team from "./funnel/components/Team";

const App = () => {
  const location = useLocation();
  const { clientname } = useParams();
  return (
    <>
      {/* <Navbar /> */}
      <LoadingScreen />
      <Routes>
        <Route exact path="/" element={<Funnel />} />
        <Route exact path="/team" element={<Team />} />
        <Route path="/apply/:clientname" element={<OnboardingForm />} />
        <Route exact path="/apply" element={<OnboardingForm />} />
        <Route exact path="/strategy-session" element={<StrategyForm />} />
        <Route exact path="/thank-you" element={<Thanks />} />
        <Route exact path="/demo/" element={<OnboardThanks  />} />
      </Routes>
    </>
  );
};

export default App;
